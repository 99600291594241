import React from "react"
import Seo from '../components/Seo'
import Layout from "../components/Layout"

export default function Home() {
  return (
    <>
        <Seo title="Polityka prywatności" description="Polityka prywatności witryny" />

        <Layout title="Polityka prywatności">
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>KTO JEST ADMINISTRATOREM DANYCH</h2>
                            <ol>
                                <li>Operatorem serwisu oraz administratorem danych osobowych przetwarzanych w serwisie maciejtymosiak.pl jest Maciej Tymosiak zamieszkały w Białych Błotach w województwie Kujawsko-Pomorskim, zwany dalej „Administratorem”.</li>
                                <li>Z Administratorem można się kontaktować za pomocą poczty elektronicznej email: <a href="mailto:maciejtymosiak7@gmail.com">maciejtymosiak7@gmail.com</a> lub telefonicznie 514 016 406</li>
                            </ol>
                            <h2>PRZETWARZANIE I POCHODZENIE DANYCH</h2>
                            <ol>
                                <li>Administrator nie gromadzi i nie udostępnia dalej danych podanych przez użytkowników, a jedynie przetwarza w celach kontaktowych z klientem.</li>
                                <li>Przetwarzanie danych osobowych (imię i nazwisko, adres e-mailu) przez Administratora jest związane wyłącznie z działalnością serwisu tj. do świadczenia usług i korzystania z witryny.</li>
                                <li>Korzystanie z witryny wiąże się z udostępnianiem przez użytkowników danych osobowych w momencie korzystania z formularza kontaktowego oraz formularza wyceny tj. brief.</li>
                                <li>Przekazywanie danych osobowych jest dobrowolne, ale warunkuje ono możliwość korzystania z usług witryny lub części jej funkcjonalności.</li>
                            </ol>
                            <h2>W JAKIM CELU PRZETWARZAM DANE OSOBOWE</h2>
                            <ol>
                                <li>Dla celów kontaktu z klientem, w tym udzielania odpowiedzi na wysłane zapytanie lub prośbę o wycenę. Podstawą prawną przetwarzania w tym wypadku jest art. 6 ust. 1 lit. f RODO, czyli prawnie uzasadniony interes realizowany przez administratora.</li>
                            </ol>
                            <h2>CZAS PRZETWARZANIA DANYCH</h2>
                            <ol>
                                <li>Dane podane w celu kontaktu z Administratorem przez klienta, będą przetwarzane przez czas udzielenia odpowiedzi na zapytanie.</li>
                            </ol>
                            <h2>PRAWA UŻYTKOWNIKA DOT. DANYCH OSOBOWYCH</h2>
                            <ol>
                                <li>Masz prawo do:
                                    <ul>
                                        <li>wniesienia sprzeciwu co do przetwarzania danych</li>
                                        <li>prawo do wniesienia skargi do organu nadzorczego, zajmującego się ochroną danych osobowych</li>
                                    </ul>
                                </li>
                                <li>W celu skorzystania z powyższych praw należy skontaktować się z Administratorem.</li>
                            </ol>
                            <h2>PODMIOTY, KTÓRYM PRZEKAZYWANE SĄ TWOJE DANE</h2>
                            <ol>
                                <li>Dane podane przez użytkownika są przekazywane przez pośrednika pocztowego https://www.emailjs.com/, aby finalnie trafić na pocztę elektroniczną Administratora.</li>
                                <li>Odbiorcami danych, oprócz Administratora mogą być również instytucje upoważnione z mocy prawa (organy wykonujące władzę publiczną w tym urzędy).</li>
                            </ol>
                            <h2>PLIKI COOKIES</h2>
                            <ol>
                                <li>Witryna wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, zapisywane i przechowywane na Twoim komputerze, laptopie, smartfonie, Smart TV itp.</li>
                                <li>Wykorzystywane pliki cookies zawierają nazwę domeny serwisu, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer ID.</li>
                                <li>Pliki cookies nie służą identyfikacji użytkowników, i na ich podstawie nie jest ustalana tożsamość użytkownika.</li>
                                <li>
                                    Korzystam z informacji zawartych w plikach cookies w celach:
                                    <ul>
                                        <li>ukrycia lub pokazania okna akceptacji polityki prywatności</li>
                                    </ul>
                                </li>
                                <li>Użytkownicy serwisu mogą dokonać zmiany ustawień dotyczących plików cookies w swojej przeglądarce internetowej. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu.</li>
                                <li>Administrator Serwisu informuje, że zmiana konfiguracji przeglądarki internetowej, która uniemożliwia lub ogranicza przechowywanie plików cookies na urządzeniu końcowym Użytkownika może spowodować ograniczenia funkcjonalności świadczonych usług.</li>
                                <li>Informacje o sposobach usunięcia cookies w przeglądarkach internetowych, znajdziesz w sekcji „Pomoc” w menu przeglądarki lub na wymienionych stronach:
                                <ul><li><a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">Edge</a></li><li><a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">Internet Explorer</a></li><li><a href="https://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">Chrome</a></li><li><a href="https://support.apple.com/kb/PH5042">Safari</a></li><li><a href="https://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">Firefox</a></li><li><a href="http://help.opera.com/Windows/12.10/pl/cookies.html">Opera</a></li></ul>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    </>
  )
}
